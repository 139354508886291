import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import MegaText from '../components/MegaText';
import LinkTable from '../components/LinkTable';
import TitleBlock from '../components/TitleBlock';
import Image2Up from '../components/Image2Up';
import InfoBits from '../components/InfoBits';

function IndexPage({ data }) {
  const frontMatter = data.allMarkdownRemark.edges[0].node.frontmatter;
  const {
    seo: { title: seoTitle, description: seoDescription },
  } = frontMatter;
  const { featureProject, webDevelopment } = frontMatter;
  return (
    <Layout>
      <SEO title={seoTitle} titleTemplate="%s" description={seoDescription} />
      <Helmet>
        <body className="bgColor" />
      </Helmet>
      <Section large>
        <MegaText text={frontMatter.megaText} />
      </Section>
      <Section large>
        <TitleBlock title={featureProject.title}>
          <Markdown>{featureProject.description}</Markdown>
        </TitleBlock>
        <Image2Up images={featureProject} />
        <InfoBits list={featureProject.list} />
      </Section>
      <Section large>
        <TitleBlock title={webDevelopment.title}>
          <Markdown>{webDevelopment.description}</Markdown>
        </TitleBlock>
        <LinkTable links={frontMatter.websites} />
      </Section>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "index" } } }) {
      edges {
        node {
          frontmatter {
            seo {
              title
              description
            }
            megaText
            featureProject {
              title
              description
              image1 {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    quality: 90
                    sizes: "(max-width: 800px) 50vw, 800px"
                    duotone: { highlight: "#ebeee7", shadow: "#222222" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image1Alt
              image2 {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    quality: 90
                    sizes: "(max-width: 800px) 50vw, 800px"
                    duotone: { highlight: "#ebeee7", shadow: "#222222" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image2Alt
              list {
                title
                description
              }
            }
            webDevelopment {
              title
              description
            }
            websites {
              website
              websiteURL
              designer
              designerURL
            }
          }
        }
      }
    }
  }
`;
