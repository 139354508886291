import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from '../cssmodules/image2Up.module.scss';
import grid from '../cssmodules/grid.module.scss';

function Image2Up({ images }) {
  const { image1, image2, alt1, alt2 } = images;
  return (
    <div className={styles.image2Up}>
      <div className={`${grid.row} ${grid.rowFlex} ${grid.rowPb4}`}>
        <div className={`${grid.colMd5} ${styles.image}`}>
          <Img fluid={image1.childImageSharp.fluid} alt={alt1} />
        </div>
        <div className={`${grid.colMd7} ${styles.image}`}>
          <Img fluid={image2.childImageSharp.fluid} alt={alt2} />
        </div>
      </div>
    </div>
  );
}

Image2Up.propTypes = {
  images: PropTypes.shape({
    image1: PropTypes.object.isRequired,
    image1Alt: PropTypes.string.isRequired,
    image2: PropTypes.object.isRequired,
    image2Alt: PropTypes.string.isRequired,
  }).isRequired,
};

export default Image2Up;
