import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/infoBit.module.scss';

function InfoBits({ list }) {
  return (
    <div className={`${grid.row} ${grid.rowFlex}`}>
      {list.map(item => (
        <div key={item.title} className={`${grid.colSm6} ${grid.colLg4}`}>
          <div className={styles.item}>
            <h3>{item.title}</h3>
            <Markdown>{item.description}</Markdown>
          </div>
        </div>
      ))}
    </div>
  );
}

InfoBits.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InfoBits;
