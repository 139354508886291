import React from 'react';
import PropTypes from 'prop-types';
import styles from '../cssmodules/linkTable.module.scss';

function LinkTable({ links }) {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <td className={`${styles.creditHeading} dogTag dogTag--bold`}>
            Design
          </td>
          <td className={`${styles.linkHeading} dogTag dogTag--bold`}>
            Website
          </td>
        </tr>
      </thead>
      <tbody>
        {links.map(({ designer, designerURL, website, websiteURL }) => (
          <tr key={websiteURL}>
            <td className={`${styles.credit} dogTag`}>
              <a href={designerURL}>{designer}</a>
            </td>
            <td className={`${styles.link} heading2`}>
              <a href={websiteURL}>{website}</a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

LinkTable.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default LinkTable;
