import React from 'react';
import PropTypes from 'prop-types';
import styles from '../cssmodules/megaText.module.scss';

function MegaText({ text }) {
  return (
    <div className={styles.megaText}>
      <p className="heading1">{text}</p>
    </div>
  );
}

MegaText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MegaText;
